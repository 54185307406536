import { createRoot } from 'react-dom/client';
import ProcessingBanner from './banner';
const body = document.querySelector('body')
const banner = document.querySelector("#processing-banner") || document.createElement('div');
body.prepend(banner);

const script = document.createElement('script');
script.type = 'text/javascript';
script.src = 'https://donorbox.org/install-popup-button.js';
script.id = 'donorbox-popup-button-installer';
script.defer = true;
body.appendChild(script);

const style = document.createElement('style');
style.innerHTML = `
 @media (min-width: 770px) {
    body .top-layout-grid nav{
      position: sticky;
      shape-outside: inset(50%);
      float: left;
  }
}
`;
body.appendChild(style);

const root = createRoot(banner);
root.render(<ProcessingBanner />);