import { useEffect, useState } from "react";
import styles from "./Banner.module.css";
import { useCollapse } from "react-collapsed";
import Donated from "./donated";

const dontShowBannnerBefore = new Date("2024-12-02T00:00:00Z");

export default function ProcessingBanner() {
    const shown = window.localStorage.getItem("donationBannerShown");
    const [isExpanded, setExpanded] = useState(false);
    const { getCollapseProps, getToggleProps } = useCollapse({
        isExpanded,
        duration: 1500,
    });
    const [interval, setInterval] = useState("m");

    const amounts = interval == "m"
        ? [5, 10, 20, 25, 50, 100, 500]
        : [10, 25, 50, 100, 200, 300, 500];
    const [amount, setAmount] = useState(25);

    const params = new URLSearchParams();
    params.append("amount", amount);
    params.append("hide_donation_meter", "true");
    params.append("default_interval", interval);
    const origin = window.location.origin;
    params.append("utm_content", JSON.stringify({ origin, shown }));
    params.append("utm_source", window.location.hostname);
    params.append("utm_medium", "banner");
    params.append("utm_campaign", "donation_banner");

    useEffect(() => {
        window.DonorBox = { widgetLinkClassName: styles.donate };
        if (window.location.hash === "#nobanner") {
            return;
        }
        const hidden = window.localStorage.getItem("donationBannerHidden");

        if (window.location.hash === "#showdonationbanner") {
            show();
            window.localStorage.removeItem("donationBannerHidden");
            return;
        }
        if (window.location.hash === "#previewdonationbanner") {
            show();
            return;
        }

        if (hidden == null) {
            const now = new Date();
            if (now < dontShowBannnerBefore) {
                return;
            }
            show();
            return;
        }
        const hide = new Date(hidden);
        if (hide > new Date()) {
            return;
        }
        show();
    }, []);

    function show() {
        window.localStorage.setItem(
            "donationBannerShown",
            parseInt(shown ?? "0") + 1,
        );
        setExpanded(true);
    }

    function close() {
        setExpanded(false);
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(4, 0, 0, 0);

        window.localStorage.setItem("donationBannerHidden", tomorrow);
    }
    function dismiss() {
        setExpanded(false);

        const never = new Date(2999, 0, 1);

        window.localStorage.setItem("donationBannerHidden", never);
    }

    function updateAmount(e) {
        // remove anything that is not a number
        const clean = e.target.value.replace(/[^0-9]/g, "");
        e.target.value = `$${clean}`;
        const amount = parseInt(clean);
        if (isNaN(amount)) {
            return;
        }
        console.log(amount);
        setAmount(amount);
    }

    return (
        <div
            className={`${styles.banner}`}
            {...getCollapseProps()}
        >
            <div className={styles.container}>
                <div className={styles.calltoaction}>
                    <div className={styles.calltoaction_inner}>
                        <div className={styles.calltoaction_desktop}>
                            <div>
                                <h1>Can You Chip In?</h1>
                                <p>
                                    At Processing Foundation, we’re imagining
                                    software that is creative, equitable, and
                                    accessible to all. In the next few months,
                                    we are releasing the newest versions of
                                    Processing, p5.js, and the p5.js Editor. The
                                    Processing Foundation relies on online
                                    donations to help us make creative coding
                                    accessible to everyone. We'd be deeply
                                    grateful if you'd join the hundreds of users
                                    that support us financially.
                                </p>
                                <p>
                                    <strong>
                                        100% of your donation will directly fund
                                        software development
                                    </strong>, supporting the dedicated
                                    contributors who work tirelessly to keep our
                                    software up-to-date, reliable, and
                                    accessible. Our resources help millions of
                                    students, artists, designers, educators all
                                    over the world—so if you want to support
                                    technology accessibility for all,{" "}
                                    <strong>please pitch in</strong>.
                                </p>
                            </div>
                        </div>
                        <div className={styles.calltoaction_mobile}>
                            <h1>Can You Chip In?</h1>
                            <p>
                                At Processing Foundation, we’re imagining
                                software that is creative, equitable, and
                                accessible to all. The Processing Foundation
                                relies on online donations to help us make
                                creative coding accessible to everyone.
                            </p>
                            <p>
                                <strong>
                                    100% of your donation will directly fund
                                    software development
                                </strong>, supporting the dedicated contributors
                                who work tirelessly to keep our software
                                up-to-date, reliable, and accessible. So if you
                                want to support technology accessibility for
                                all, <strong>please pitch in</strong>
                            </p>
                        </div>

                        <div className={styles.calltoaction_skip_container}>
                            <a
                                className={`${styles.donatemobile} ${styles.mobileonly}`}
                                mobile="true"
                                href={`https://processingfoundation.org/donate?${params.toString()}`}
                            >
                                Donate
                            </a>
                            <a
                                className={styles.calltoaction_skip}
                                onClick={() => dismiss()}
                            >
                                <Donated /> I already donated
                            </a>
                        </div>
                    </div>
                    <div className={styles.calltoaction_arrow} />
                </div>
                <div className={styles.action}>
                    <div className={styles.intervalSelect}>
                        <div
                            className={`${
                                interval == "o" && styles.active
                            } ${styles.interval}`}
                            onClick={() => setInterval("o")}
                        >
                            One-time
                        </div>
                        <div
                            className={`${
                                interval == "m" && styles.active
                            } ${styles.interval}`}
                            onClick={() => setInterval("m")}
                        >
                            <div className={styles.star} /> Monthly
                        </div>
                    </div>
                    <div className={styles.amountSelect}>
                        {amounts.map((_amount, index) => (
                            <div
                                className={`${styles.amount} ${
                                    amount == _amount && styles.active
                                }`}
                                key={index}
                                onClick={() => setAmount(_amount)}
                            >
                                ${_amount}
                            </div>
                        ))}
                        <input
                            className={`${styles.amount} ${styles.customamount} ${
                                !amounts.includes(amount) && styles.active
                            }`}
                            type="string"
                            placeholder="Other amount"
                            onClick={updateAmount}
                            onChange={updateAmount}
                        />
                    </div>
                    <a
                        className={styles.donate}
                        href={`https://donorbox.org/building-together?${params.toString()}`}
                        target="_blank"
                    >
                        Proceed with the donation
                    </a>
                    <a
                        href="https://processingfoundation.report/#sustaining-our-community"
                        className={styles.moreinfo}
                        target="_blank"
                    >
                        Where does my donation go?
                    </a>
                </div>
                <div className={styles.close} onClick={close}>
                    <svg
                        width="23"
                        height="23"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20.4418 2L2.00021 20.4272"
                            stroke="#9CA3AF"
                            stroke-width="3.72285"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M2 2L20.4416 20.4272"
                            stroke="#9CA3AF"
                            stroke-width="3.72285"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
            </div>
            <script
                type="text/javascript"
                defer
                src="https://donorbox.org/install-popup-button.js"
                id="donorbox-popup-button-installer"
            >
            </script>
        </div>
    );
}
